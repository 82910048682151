<template>
  <div>
    <v-navigation-drawer
      class="hidden-md-and-up header-one-page-nav-drawer"
      v-model="drawer"
      fixed
      width="320"
    >
      <v-list-item class="pa-5">
        <div class="logo">
          <router-link to="/">
          <img
            src="../../assets/images/logo/SIDlogo-black2.png"
            alt="Creative Agency Logo"
          />
          </router-link>
        </div>
        <v-spacer></v-spacer>
        <v-btn
          class="close-icon"
          icon
          @click="drawer = !drawer"
          v-html="iconSvg(closeIcon)"
        >
        </v-btn>
      </v-list-item>

      <scrollactive
        active-class="v-btn--active"
        bezier-easing-value=".5,0,.35,1"
        :offset="71"
      >
        <v-list>
          <v-list-group
            v-for="item in items"
            :key="item.title"
            v-model="item.active"
          >
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title v-text="item.title"></v-list-item-title>
              </v-list-item-content>
            </template>

            <v-list-item
              v-for="child in item.items"
              :key="child.title"
              :to="child.to"
            >
                <v-list-item-content>
                  <v-list-item-title v-text="child.title"></v-list-item-title>
                </v-list-item-content>
            </v-list-item>
          </v-list-group>
        </v-list>
      </scrollactive>
    </v-navigation-drawer>
    <!-- End sidebar mobile menu -->

    <v-app-bar
      fixed
      elevate-on-scroll
      class="header-one-page-nav header-one-page-nav-two"
    >
      <!-- navbar mobile -->
      <v-container class="pa-0 d-flex flex-row justify-space-between">
        <v-btn
          icon
          class="ma-0 pa-0 hidden-md-and-up"
          @click="drawer = !drawer"
          v-html="iconSvg(icon)"
        >
        </v-btn>
        <v-spacer></v-spacer>
        <v-toolbar-items class="height-auto ml--35">
          <div class="slide-btn d-flex justify-center" style="width: 150px; height: 40px; font-size: 10px !important; line-height: 20px !important;">
            <router-link class="rn-button-style--2 btn_solid" to="/contact-us"
              >reach us
            </router-link>
          </div>
        </v-toolbar-items>
      </v-container>
      <!-- end of navbar mobile -->

      <!-- End brand logo -->
      <!-- End header menu item -->
      <!-- <a
        class="rn-btn"
        href="https://themeforest.net/checkout/from_item/30195230?license=regular"
      > 
        <span>buy now</span>
      </a> -->
      <!-- End mobile menu icon -->
    </v-app-bar>
    <!-- End top header navbar -->
  </div>
</template>

<script>
import feather from "feather-icons";
import socialTwo from "../social/SocialTwo";
export default {
  components: {
    socialTwo,
  },
  data: () => ({
    drawer: false,
    offsetTop:0,
    items: [
      {
        active: true,
        title: 'Solutions',
        items: [
          { title: 'Data Engineering',
            to: '/solutions/data',
          },
          { title: 'Application Engineering',
            to: '/solutions/application',
          },
          { title: 'Security Engineering',
            to: '/solutions/security',
          },
        ],
      },
      {
        active: false,
        title: 'Industries',
        items: [
          { title: 'Banking & Financial Services',
            to: '/industries/banking-financial-services',
          },
          { title: 'Telecommunications',
            to: '/industries/telecommunications',
          },
          { title: 'Fast Moving Consumer Goods',
            to: '/industries/fast-moving-consumer-goods',
          },
          { title: 'Selected Governments Agencies, Military, Defense & Police',
            to: '/industries/governments-agencies-military-defense-police',
          },
        ],
      },
      {
        active: false,
        title: 'Insight',
        items: [
          { title: 'Perspectives',
            to: '/perspectives',
          },
          { title: 'Articles & Resources',
            to: '/article',
          },
        ],
      },
      {
        active: false,
        title: 'About',
        items: [
          { title: 'About Us',
            to: "/#whySibernetik",
          },
          { title: 'News & Event',
            to: "/about/news&event",
          },
          { title: 'Our Partners',
            to: "/#partners",
          },
          { title: 'Siberlabs',
            to: "/#siberlabs",
          },
        ],
      },
    ],
    icon: "menu",
    closeIcon: "x",
  }),
  methods: {
    getOffsetTop(value){
      let vm = this
      console.log(value);
    },
    iconSvg(icon) {
      return feather.icons[icon].toSvg();
    },
  },
};
</script>
<style>
svg.feather.feather-menu {
    background-color:rgb(0, 0, 0, 0) !important;
  }
</style>