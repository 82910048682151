<template>
  <div>
    <v-navigation-drawer class="hidden-md-and-up header-one-page-nav-drawer" v-model="drawer" fixed width="320">
      <v-list-item class="pa-5">
        <div class="logo">
          <img src="../../assets/images/logo/logo-symbol-dark.png" alt="Creative Agency Logo" />
        </div>
        <v-spacer></v-spacer>
        <v-btn class="close-icon" icon @click="drawer = !drawer" v-html="iconSvg(closeIcon)">
        </v-btn>
      </v-list-item>

      <scrollactive active-class="v-btn--active" bezier-easing-value=".5,0,.35,1" :offset="71">
        <v-list>
          <v-list-item :ripple="false" v-for="item in items" :key="item.title" :to="item.to" link
            class="scrollactive-item">
            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <!-- End mobile menu sidebar item list -->
        </v-list>
      </scrollactive>
    </v-navigation-drawer>

    <!-- Start Systembar -->
    <v-expand-transition>
      <v-system-bar v-if="collapseSystembar" app height="25" class="d-flex" color="#dd8732" style="padding-right: 5%;" fixed>
        <v-spacer></v-spacer>
        <div>
          <li style="list-style-type: none;">
            <router-link v-for="item2 in items2" :key="item2.title" :to="item2.to" class="secondary-menu"
              style="margin-right: 20px">{{ item2.title }}
            </router-link>
          </li>
        </div>
      </v-system-bar>
    </v-expand-transition>
    <!-- End Systembar -->

    <!-- Start of Navbar menu -->
    <v-app-bar height="60" class="py-auto" style="padding-right: 5%; padding-left: 5%;" color="#152939" app>
      <v-toolbar-items class="hidden-xs-only hidden-sm-only height-auto">
        <scrollactive active-class="v-btn--active" bezier-easing-value=".5,0,.35,1" :offset="71"
          class="d-none d-md-flex d-lg-flex d-xl-flex">
          <ListMenuMenu/>
        </scrollactive>
      </v-toolbar-items>
      <v-spacer></v-spacer>
      <router-link to="/" class="logo mr-2">
      <!-- <router-link to="/siber-web" class="logo mr-2"> -->
        <v-img src="../../assets/images/logo/SIDlogo2.png" style="height: auto; width: 160px;"/>
      </router-link>
    </v-app-bar>
    <!-- End of Navbar menu -->
  </div>
</template>

<script>
import feather from "feather-icons";
import socialTwo from "../social/SocialTwo";
import ListMenuMenu from "../../views/ListMenuMenu.vue";

export default {
  components: {
    socialTwo,
    ListMenuMenu
  },
  data: () => ({
    drawer: false,
    offsetTop: 0,
    items: [
      { title: "Solution", to: "#solution" },
      { title: "Service", to: "#service" },
      { title: "Insight", to: "#insight" },
      { title: "Use Cases", to: "#usecases" },
    ],
    items2: [
      { title: "LinkedIn Page", to: "/siber-career" },
      { title: "Contact Us", to: "/contact-us" },
    ],
    icon: "menu",
    closeIcon: "x",
    collapseSystembar: true
  }),
  methods: {
    getOffsetTop(value) {
      let vm = this
      console.log(value);
    },
    iconSvg(icon) {
      return feather.icons[icon].toSvg();
    },
  },
  mounted() {
    window.addEventListener('scroll', (e) => {
      // console.log(window.pageYOffset)
      if(window.pageYOffset > 100) {
        this.collapseSystembar = false
      } else if(window.pageYOffset <= 100) {
        this.collapseSystembar = true
      }
    })
  }
};
</script>
<style>
svg.feather.feather-menu {
  background-color: rgb(0, 0, 0, 0) !important;
}

.secondary-menu {
  color: #ededed !important;
  font-weight: 700;
}
</style>