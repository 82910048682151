<template>
  <div class="menu-wrapper">
    <!-- <v-menu offset-y :close-on-content-click="false" :location-strategy="menuLocationStrategy"
      transition="scroll-y-transition">
      <template v-slot:activator="{ on, attrs }">
        <v-btn class="title-menu" :ripple="false" text v-bind="attrs" v-on="on">
          About Us
        </v-btn>
      </template>
      <v-card class="menu-card pa-10">
        <v-list>
          <v-list-item to="/#whySibernetik">
            <v-list-item-subtitle style="color: green !important;">Why Sibernetik</v-list-item-subtitle>
            <span>We are Engineering First</span>
          </v-list-item>
          <v-list-item to="/#managementTeam">
            <v-list-item-icon>
              <v-icon>
                <v-img src="../assets/images/menu/teamwork.png" />
              </v-icon>
            </v-list-item-icon>
            <v-list-item-subtitle style="color: green !important;">Management Team</v-list-item-subtitle>
            <span>Experience, Passionate and Dynamic professionals</span>
          </v-list-item>
          <v-list-item to="/#partners">
            <v-list-item-icon>
              <v-icon>
                <v-img src="../assets/images/menu/hand-shake.png" />
              </v-icon>
            </v-list-item-icon>
            <v-list-item-subtitle style="color: green !important;">Partners</v-list-item-subtitle>
            <span>Data Infrastructure platform and Security</span>
          </v-list-item>
          <v-list-item to="/#siberlabs">
            <v-list-item-icon>
              <v-icon>
                <v-img src="../assets/images/menu/flask.png" />
              </v-icon>
            </v-list-item-icon>
            <v-list-item-subtitle style="color: green !important;">Siber Labs</v-list-item-subtitle>
            <span>Siber Labs is the place for you</span>
          </v-list-item>
          <v-list-item to="/#recognitionAwards">
            <v-list-item-icon>
              <v-icon>
                <v-img src="../assets/images/menu/award.png" />
              </v-icon>
            </v-list-item-icon>
            <v-list-item-subtitle style="color: green !important;">Recognition and Awards</v-list-item-subtitle>
            <span>Our Awards and Recognitions in recent years</span>
          </v-list-item>
          <v-list-item to="/#clients">
            <v-list-item-icon>
              <v-icon>
                <v-img src="../assets/images/menu/rating.png" />
              </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-subtitle style="color: green !important;">Clients</v-list-item-subtitle>
              <span>Our previous involvements</span>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card>
    </v-menu>
    <v-menu bottom min-width="240" offset-y transition="scroll-y-transition">
      <template v-slot:activator="{ on, attrs }">
        <v-btn class="title-menu" :ripple="false" text v-bind="attrs" v-on="on">
          What We Do
        </v-btn>
      </template>
      <v-card class="menu-card">
        <v-list>
          <v-list-item>
            <v-list-item-title>Services</v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-list-item-subtitle style="margin-left: 20px;">Data Services</v-list-item-subtitle>
          </v-list-item>
          <v-list-item to="/application-services">
            <v-list-item-subtitle style="margin-left: 20px; color: green !important;">Application
              Services</v-list-item-subtitle>
          </v-list-item>
          <v-list-item to="/security-services">
            <v-list-item-subtitle style="margin-left: 20px; color: green !important;">Security
              Services</v-list-item-subtitle>
          </v-list-item>
          <v-list-item>
            <v-list-item-subtitle style="margin-left: 20px;">Managed Services</v-list-item-subtitle>
          </v-list-item>
        </v-list>

        <v-list>
          <v-list-item>
            <v-list-item-title>Products</v-list-item-title>
          </v-list-item>
          <v-list-item to="/datapipe">
            <v-list-item-subtitle style="margin-left: 20px;">Datapipe</v-list-item-subtitle>
          </v-list-item>
          <v-list-item to="/geopipe">
            <v-list-item-subtitle style="margin-left: 20px; color: green !important;">Geopipe</v-list-item-subtitle>
          </v-list-item>
          <v-list-item to="/securepipe">
            <v-list-item-subtitle style="margin-left: 20px; color: green !important;">Securepipe</v-list-item-subtitle>
          </v-list-item>
          <v-list-item to="/in-platform">
            <v-list-item-subtitle style="margin-left: 20px; color: green !important;">In-platform</v-list-item-subtitle>
          </v-list-item>
        </v-list>

        <v-list>
          <v-list-item>
            <v-list-item-title>Solutions</v-list-item-title>
          </v-list-item>
          <v-list-item to="/data-streaming">
            <v-list-item-subtitle style="margin-left: 20px;">Realtime Data Streaming & Processing</v-list-item-subtitle>
          </v-list-item>
          <v-list-item to="/operational-data-store">
            <v-list-item-subtitle style="margin-left: 20px; color: green !important;">Operational Data Store &
              Mart</v-list-item-subtitle>
          </v-list-item>
          <v-list-item to="/limit-management-system">
            <v-list-item-subtitle style="margin-left: 20px; color: green !important;">Limit Management
              System</v-list-item-subtitle>
          </v-list-item>
          <v-list-item to="/psre-hub">
            <v-list-item-subtitle style="margin-left: 20px; color: green !important;">PSrE Hub</v-list-item-subtitle>
          </v-list-item>
          <v-list-item to="/marine-surveillance-system">
            <v-list-item-subtitle style="margin-left: 20px; color: green !important;">Marine Surveillance
              System</v-list-item-subtitle>
          </v-list-item>
          <v-list-item to="/air-traffic-surveillance">
            <v-list-item-subtitle style="margin-left: 20px; color: green !important;">Air Traffic Surveillance
              System</v-list-item-subtitle>
          </v-list-item>
          <v-list-item to="/ground-asset-management">
            <v-list-item-subtitle style="margin-left: 20px; color: green !important;">Ground Asset Management
              System</v-list-item-subtitle>
          </v-list-item>
        </v-list>
      </v-card>
    </v-menu>
    <v-menu bottom min-width="240" offset-y transition="scroll-y-transition">
      <template v-slot:activator="{ on, attrs }">
        <v-btn class="title-menu" :ripple="false" text v-bind="attrs" v-on="on">
          Markets
        </v-btn>
      </template>
      <v-card class="menu-card">
        <v-list>
          <v-list-item>
            <v-list-item-subtitle>Financial Services</v-list-item-subtitle>
          </v-list-item>
          <v-list-item>
            <v-list-item-subtitle>Telecommunications</v-list-item-subtitle>
          </v-list-item>
          <v-list-item>
            <v-list-item-subtitle>Consumer Goods</v-list-item-subtitle>
          </v-list-item>
          <v-list-item>
            <v-list-item-subtitle>Utilities</v-list-item-subtitle>
          </v-list-item>
          <v-list-item>
            <v-list-item-subtitle>Governments</v-list-item-subtitle>
          </v-list-item>
        </v-list>
      </v-card>
    </v-menu>
    <v-menu bottom min-width="240" offset-y transition="scroll-y-transition">
      <template v-slot:activator="{ on, attrs }">
        <v-btn class="title-menu" :ripple="false" text v-bind="attrs" v-on="on">
          News and Events
        </v-btn>
      </template>
      <v-card class="menu-card">
        <v-list>
          <v-list-item to="/news">
            <v-list-item-subtitle style="color: green !important;">News</v-list-item-subtitle>
          </v-list-item>
          <v-list-item to="/events">
            <v-list-item-subtitle style="color: green !important;">Event</v-list-item-subtitle>
          </v-list-item>
        </v-list>
      </v-card>
    </v-menu>
    <v-menu bottom min-width="240" offset-y transition="scroll-y-transition">
      <template v-slot:activator="{ on, attrs }">
        <v-btn class="title-menu" :ripple="false" text v-bind="attrs" v-on="on">
          Insight
        </v-btn>
      </template>
      <v-card class="menu-card">
        <v-list>
          <v-list-item>
            <v-list-item-subtitle>Features</v-list-item-subtitle>
          </v-list-item>
          <v-list-item>
            <v-list-item-subtitle>Case Studies</v-list-item-subtitle>
          </v-list-item>
          <v-list-item>
            <v-list-item-subtitle>Client Conversations</v-list-item-subtitle>
          </v-list-item>
        </v-list>
      </v-card>
    </v-menu>

    <v-menu bottom min-width="240" offset-y transition="scroll-y-transition">
      <template v-slot:activator="{ on, attrs }">
        <v-btn class="title-menu" :ripple="false" text v-bind="attrs" v-on="on">
          Career
        </v-btn>
      </template>
      <v-card class="menu-card">
        <v-list>
          <v-list-item to="/siberian">
            <v-list-item-subtitle style="color: green !important;">Siberian</v-list-item-subtitle>
          </v-list-item>
          <v-list-item>
            <v-list-item-subtitle>Rewards & Benefits</v-list-item-subtitle>
          </v-list-item>
          <v-list-item>
            <v-list-item-title class="joinus">Join Us</v-list-item-title>
          </v-list-item>
          <v-list-item style="margin-left: 20px;">
            <v-list-item-subtitle>Internship</v-list-item-subtitle>
          </v-list-item>
          <v-list-item style="margin-left: 20px;">
            <v-list-item-subtitle>Fresh Graduate</v-list-item-subtitle>
          </v-list-item>
          <v-list-item style="margin-left: 20px;">
            <v-list-item-subtitle>Professional</v-list-item-subtitle>
          </v-list-item>
        </v-list>
      </v-card>
    </v-menu> -->

    <v-menu bottom offset-y transition="scroll-y-transition" v-model="showMenu.solutions">
      <template v-slot:activator="{ on, attrs }">
        <v-btn id="solution-btn" class="title-menu" :ripple="false" text v-bind="attrs" v-on="on"
          @click="showLogoControl('in');">
          Solution
        <v-icon dark right small>mdi-menu-down</v-icon>
          <v-icon x-large color="white" v-if="showMenu.solutions"
            style="position: absolute; bottom: -161%;z-index: 100;">mdi-menu-up</v-icon>
        </v-btn>
      </template>
      <v-card class="menu-card">
        <v-container fluid>
          <v-row></v-row><!-- spacer -->
          <v-row>
            <strong style="font-size: 40px; padding-left: 3rem;">Discover our capabilities in providing
              solutions for your needs</strong>
          </v-row>
          <v-row>
            <v-col md="4">

              <v-list style="max-width: calc(100%); padding: 0px 0px 20px !important;">
                <v-list-item class="custom-list-item" to="/solutions/data">
                  <v-list-item-icon>
                    <v-icon x-large id="icon-color">mdi-database-outline</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content class="custom-list-content">
                    <v-list-item-subtitle class="sub-menu">Data Engineering</v-list-item-subtitle>
                    <span>Harness the power of data for business growth</span>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item class="custom-list-item" to="/solutions/application">
                  <v-list-item-icon>
                    <v-icon x-large id="icon-color">mdi-application</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content class="custom-list-content">
                    <v-list-item-subtitle>Application Engineering</v-list-item-subtitle>
                    <span>Accelerate transformation for exponential growth</span>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item class="custom-list-item" to="/solutions/security">
                  <v-list-item-icon>
                    <v-icon x-large id="icon-color">mdi-security</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content class="custom-list-content">
                    <v-list-item-subtitle>Security Engineering</v-list-item-subtitle>
                    <span>Integrated, Intelligence, Engineering</span>
                  </v-list-item-content>
                </v-list-item>
                <!-- <v-list-item class="custom-list-item">
            <v-list-item-icon>
              <v-icon>mdi-more</v-icon>
            </v-list-item-icon>
            <v-list-item-content class="custom-list-content">
              <v-list-item-subtitle>Others</v-list-item-subtitle>
              <span>Placeholder</span>
            </v-list-item-content>
          </v-list-item> -->
              </v-list>
            </v-col>
            <v-col>
              <span
                style="max-width: calc(100%); height: 100%; display: flex; justify-content: center; align-items: flex-start; font-size:20px !important;">
                <strong>
                  Experience, passionate and dynamic professionals with regular refreshed necessary knowledge and skill
                  set. We combine logic, creativity and curiosity to build, solve, and create.
                  <br />
                  <!-- Every day, we help clients engage with new technology paradigms, creatively building solutions that solve their most pressing business challenges and move them to the forefront of their industry. -->
                </strong>
              </span>
            </v-col>
            <v-col>

              <div style="display:flex; justify-content: center; align-items: flex-start; height: calc(100%);">
                <v-slide-x-transition>
                  <img id="sibernetikLogo" v-if="showLogo == true" src="../assets/images/logo/logo192.png"
                    style="width: 200px;" class="pt-2">
                </v-slide-x-transition>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-menu>

    <v-menu bottom min-width="240" offset-y transition="scroll-y-transition" v-model="showMenu.industries">
      <template v-slot:activator="{ on, attrs }">
        <v-btn class="title-menu" :ripple="false" text v-bind="attrs" v-on="on">
          Industries <v-icon dark right small>mdi-menu-down</v-icon>
          <v-icon x-large color="white" v-if="showMenu.industries"
            style="position: absolute; bottom: -161%;z-index: 100;">mdi-menu-up</v-icon>
        </v-btn>
      </template>
      <v-card class="menu-card">
        <v-container fluid>
          <v-row>
            <v-col>
              <v-row></v-row> <!-- spacer -->
              <v-row>
                <strong style="font-size: 30px; padding-left: 3rem;">
                  Accelerating innovation driven by cutting edge digital engineering capabilities
                  <!-- <strong style="font-size: 30px;"></strong> -->
                </strong>
              </v-row>
              <strong
                style="font-size: 20px; max-width: calc(100%); max-height: 100%; display: flex; justify-content: center; align-items: flex-start; padding-left: 2.5rem;"
                class="pt-7">
                Your industry is on the move. We understand where it’s going. We constantly invest in our employees to
                build domain expertise as part of their career journeys. This enables us to bring unmatched understanding
                of the unique challenges and opportunities of specific industries to our clients.
              </strong>
            </v-col>
            <v-col>
              <v-list>
                <v-list-item class="custom-list-item" v-on:click="$router.push({ path : '/industries/banking-financial-services'})">
                  <!-- > -->
                  <v-list-item-icon>
                    <v-icon x-large id="icon-color">mdi-bank</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content class="custom-list-content">
                    <v-list-item-subtitle>Banking & Financial Services</v-list-item-subtitle>
                    <!-- <span>Placeholder</span> -->
                  </v-list-item-content>
                </v-list-item>
                <v-list-item class="custom-list-item" v-on:click="$router.push({ path : '/industries/telecommunications'})">
                  <!-- > -->
                  <v-list-item-icon>
                    <v-icon x-large id="icon-color">mdi-radio-tower</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content class="custom-list-content">
                    <v-list-item-subtitle>Telecommunications</v-list-item-subtitle>
                    <!-- <span>Placeholder</span> -->
                  </v-list-item-content>
                </v-list-item>
                <v-list-item class="custom-list-item" v-on:click="$router.push({ path : '/industries/fast-moving-consumer-goods'})">
                  <!-- > -->

                  <v-list-item-icon>
                    <v-icon x-large id="icon-color">mdi-food</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content class="custom-list-content">
                    <v-list-item-subtitle>Fast Moving Consumer Goods</v-list-item-subtitle>
                    <!-- <span>Placeholder</span> -->
                  </v-list-item-content>
                </v-list-item>
                <v-list-item class="custom-list-item" v-on:click="$router.push({ path : '/industries/governments-agencies-military-defense-police'})">
                <!-- > -->
                  <v-list-item-icon>
                    <v-icon x-large id="icon-color">mdi-database-outline</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content class="custom-list-content">
                    <v-list-item-subtitle>Selected Governments Agencies, Military, Defense & Police</v-list-item-subtitle>
                    <!-- <span>Placeholder</span> -->
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-menu>

    <v-menu bottom min-width="240" offset-y transition="scroll-y-transition" v-model="showMenu.insight">
      <template v-slot:activator="{ on, attrs }">
        <v-btn class="title-menu" :ripple="false" text v-bind="attrs" v-on="on">
          Insight <v-icon dark right small>mdi-menu-down</v-icon>
          <v-icon x-large color="white" v-if="showMenu.insight"
            style="position: absolute; bottom: -161%;z-index: 100;">mdi-menu-up</v-icon>
        </v-btn>
      </template>
      <v-card class="menu-card">
        <v-container fluid>
          <v-row class="pa-10">
            <v-col>
              <router-link to="/perspectives">
                <div style="display: flex; justify-content: center; align-items: center;">
                  <div style="position: relative;">
                    <v-img src="../assets/images/contents/articles/perspective.jpeg" width="360px" height="470px"
                      style="z-index: 0;" />
                    <v-card v-on:click="" to="/perspectives"
                      style="position:absolute; width: 360px;bottom:0%; z-index: 1; opacity: 0.7; padding: 10px 20px;"
                      flat tile>
                      <v-card-title>
                        <h4>
                          <strong>Perspectives</strong>
                        </h4>
                      </v-card-title>
                      <v-card-text>
                        <strong>
                          Our experts provide thought leadership on the technology landscape
                        </strong>
                        <!-- <span>
                          dolore perspiciatis nemo ipsum laboriosam. Voluptas nulla, ab temporibus blanditiis quos odio
                          obcaecati
                          totam deserunt quibusdam.
                        </span> -->
                      </v-card-text>
                    </v-card>
                  </div>
                </div>
              </router-link>
            </v-col>
            <v-col>
              <router-link to="/article">
                <div style="position: relative;">
                  <v-img src="../assets/images/contents/articles/articles.jpeg" width="360px" height="470px"
                    style="z-index: 0;" />
                  <v-card v-on:click="" to="/article"
                    style="position:absolute; width: 360px;bottom:0%; z-index: 1; opacity: 0.7; padding: 10px 20px;" flat
                    tile>
                    <v-card-title>
                      <h4>
                        <strong>Articles & Resources</strong>
                      </h4>
                    </v-card-title>
                    <v-card-text>
                      <strong>
                        White papers, reports, and in-depth articles on hot industry topics
                      </strong>
                      <span>
                        <!-- dolore perspiciatis nemo ipsum laboriosam. Voluptas nulla, ab temporibus blanditiis quos odio
                        obcaecati
                        totam deserunt quibusdam. -->
                      </span>
                    </v-card-text>
                  </v-card>
                </div>
              </router-link>
            </v-col>
            <v-col style=" display: flex; align-items: center;">
              <v-container fluid>
                <v-row style="min-width: 400px;">
                  <strong style="font-size: 160px;line-height: 119px;">
                    D
                  </strong>
                  <div style="display: flex; flex-direction: column; justify-content: center; align-items: center;">
                    <strong style="font-size: 30px;">iscover New Stances </strong>
                    <strong style="font-size: 17px;">on how we see the digital world</strong>
                  </div>
                </v-row>
                <v-row>
                  <strong
                    style="max-width: calc(100%); height: 100%; display: flex; justify-content: left; align-items: flex-start; font-size: 20px;"
                    class="pt-7">
                    Our technology and Industry experts share their knowledge to help you understand and tackle today's
                    business challenges and impact outcomes
                  </strong>
                </v-row>
              </v-container>
            </v-col>
          </v-row>
        </v-container>
        <!-- <v-list>
          <v-list-item>
            <v-list-item-subtitle>Perspectives</v-list-item-subtitle>
          </v-list-item>
          <v-list-item>
            <v-list-item-subtitle>Articles & Resources</v-list-item-subtitle>
          </v-list-item>
        </v-list> -->
      </v-card>
    </v-menu>

    <v-menu bottom min-width="240" offset-y transition="scroll-y-transition" v-model="showMenu.about" close-on-click>
      <template v-slot:activator="{ on, attrs }">
        <v-btn class="title-menu" :ripple="false" text v-bind="attrs" v-on="on">
          About <v-icon dark right small>mdi-menu-down</v-icon>
          <v-icon x-large color="white" v-if="showMenu.about"
            style="position: absolute; bottom: -161%;z-index: 100;">mdi-menu-up</v-icon>
        </v-btn>
      </template>
      <v-card class="menu-card">
        <v-container fluid>
          <v-row></v-row><!-- spacer -->
          <v-row></v-row><!-- spacer -->
          <v-row></v-row><!-- spacer -->
          <v-row>
            <v-col md="4">
              <v-list style="max-width: calc(100%); padding: 0px 0px 20px !important;">
                <router-link :to="{ path: '/', hash: 'whySibernetik' }">
                  <v-list-item class="custom-list-item">
                    <v-list-item-icon>
                      <v-icon x-large id="icon-color" color="lightblue">mdi-account-group-outline</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content class="custom-list-content">
                      <v-list-item-subtitle class="sub-menu">About Us</v-list-item-subtitle>
                      <span>We Are Engineering First</span>
                    </v-list-item-content>
                  </v-list-item>
                </router-link>
                <!-- <v-list-item class="custom-list-item" to="/about/news&event">
                  <v-list-item-icon>
                    <v-icon x-large id="icon-color">mdi-newspaper</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content class="custom-list-content">
                    <v-list-item-subtitle class="sub-menu">News & Events</v-list-item-subtitle>
                    <span>Our Latest News & Events</span>
                  </v-list-item-content>
                </v-list-item> -->
                <router-link :to="{ path: '/', hash: 'partners' }">
                  <v-list-item class="custom-list-item">
                    <v-list-item-icon>
                      <v-icon x-large id="icon-color">mdi-handshake-outline</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content class="custom-list-content">
                      <v-list-item-subtitle class="sub-menu">Our Partners</v-list-item-subtitle>
                      <span>Our Partner Ecosystem</span>
                    </v-list-item-content>
                  </v-list-item>
                </router-link>
                <router-link :to="{ path: '/', hash: 'siberlabs' }">
                  <v-list-item class="custom-list-item">
                    <v-list-item-icon>
                      <!-- <v-icon x-large> </v-icon> -->
                      <img style="width: 40px !important;" src="../assets/images/logo/logo192.png">
                    </v-list-item-icon>
                    <v-list-item-content class="custom-list-content">
                      <v-list-item-subtitle class="sub-menu">Siberlabs</v-list-item-subtitle>
                      <span>Our Sandbox and Live-Showcase</span>
                    </v-list-item-content>
                  </v-list-item>
                </router-link>
              </v-list>
            </v-col>
            <v-col md="3">
              <v-row class="pt-3">
                <strong style="font-size: 20px;">Enable enterprises digital and culture transformation with data. START
                  WITH ENGINEERING FIRST</strong>
              </v-row>
              <!-- <v-row>
                Lorem ipsum dolor sit amet consectetur, adipisicing elit. Id veniam accusantium voluptate tempore nulla ea
                quas, modi facere perspiciatis in, corrupti quasi distinctio magnam enim itaque eveniet, at impedit
                temporibus.
              </v-row> -->
            </v-col>
            <v-col offset="1" class="pr-10">
              <v-row class="pt-3">
                <h4>
                  Head Office
                </h4>
                <span>
                  Equity Tower Lt.35 Unit D,G dan H SCBD Lot 9
                  Jl. Jend Sudirman Kav 52-53 Kebayoran Baru
                  Jakarta Selatan, DKI Jakarta, 12190
                  Indonesia
                </span>
              </v-row>
              <v-row>
                <h4>
                  Workshop
                </h4>
                <span>
                  Jl. Taman Jenggala No.5, RT.5/RW.1,
                  Selong, Kecamatan Kebayoran Baru,
                  Kota Jakarta Selatan, DKI Jakarta 12110
                  Indonesia
                </span>
              </v-row>
              <v-row>
                <span>
                  P +622 129 277 994
                </span>
              </v-row>
              <v-row>
                <span>
                  F +622 129 277 994
                </span>
              </v-row>
              <v-row>
                <span>
                  E info@sibernetik.co.id
                </span>
              </v-row>
              <v-row></v-row><!-- spacer -->
              <v-row></v-row><!-- spacer -->
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-menu>
  </div>
</template>
<script>
export default {
  data() {
    return {
      menuNavbar: false,
      menuLocationStrategy: "static",
      showLogo: false,
      selectedMenu: '',
      showMenu: {
        solutions: false,
        industries: false,
        insight: false,
        about: false,
      },
    };
  },
  watch: {
    showLogo(e) {
      // console.log(e)
    },
    showMenu(e) {
      // console.log(e)
      if (e) {

      } else {
        this.selectedMenu = ''
      }
    }
  },
  created() {
    window.addEventListener('scroll', this.handleScroll);
  },
  mounted() {
  },
  methods: {
    handleScroll(e) {
      // console.log('page is scrolling')

      this.showMenu = {
        solutions: false,
        industries: false,
        insight: false,
        about: false,
      }
    },
    handleActive(e) {
      // console.log(e)
    },
    showLogoControl(action) {
      this.showLogo = false
      setTimeout(() => {
        this.showLogo = true
      }, 500);
    }
  }
};
</script>
<style lang="scss" scoped>
.menu-card {
  max-width: calc(70%);
  // width: 1000px;
  // margin: 0 130px;
  // margin-bottom: 100px;
  left: 100px;
  // border-radius: 15px;
  // display: flex;
  // flex-direction: row;
}

.title-menu {
  font-size: 20px !important;
  color: #ededed;
  // font-weight:600;
  letter-spacing: 1px !important;
}

.custom-list-item {
  // all: revert !important;
  display: flex !important;
  flex-direction: row !important;
  margin: 0px 10px 10px !important;
}

#icon-color {
  color: black; //#e08121 !important;
}

.sub-menu {
  font-size: 20px !important;
}

.custom-list-icon {}

.custom-list-content {}
</style>